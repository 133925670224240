import React from 'react';
import classNames from 'classnames';
import Image from '../Image';
import './styles.scss';
import PropTypes from 'prop-types';
import { MEDIA_TYPE } from '../../utils/constants';
import Carousel from '../Carousel';

const Hero = ({
  mediaSource,
  mediaType,
  textOverlay,
  className,
  programLogo,
  heroGraphic,
}) => {
  const isVideo = mediaType && Object.values(MEDIA_TYPE).includes(mediaType);
  const isMultiImage = Array.isArray(mediaSource);

  if (isVideo) {
    return (
      <div className={classNames('hero', className)}>
        <video autoPlay loop muted className="hero__video">
          <source src={mediaSource} type={mediaType}></source>
        </video>
        {textOverlay}
      </div>
    );
  } else if (isMultiImage) {
    return (
      <div className={classNames('hero', className)}>
        <Carousel
          headingClassOverride={'mb-0'}
          imageContainerClassOverride={'mb-0'}
          slideClassOverride={'mb-0'}
          settings={{ fade: true }}
          slides={mediaSource}
        />
        {textOverlay}
      </div>
    );
  } else {
    return (
      <div className={classNames('hero', className)}>
        <Image filename={programLogo} className="hero__logo" />
        <Image filename={mediaSource} className="hero__img" />
        {textOverlay}
        <div>
          <Image filename={heroGraphic} className="hero__graphic" />
        </div>
      </div>
    );
  }
};

Hero.defaultProps = {
  textOverlay: null,
  className: null,
};

Hero.propTypes = {
  /** A path to video or a filename for an image (using the Image Component)
   * or an array of objects for multi image slider
   */
  mediaSource: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
      })
    ),
  ]).isRequired,
  /** The type used for the video */
  mediaType: PropTypes.oneOf(Object.values(MEDIA_TYPE)),
  /** Text overlay for the image/video. The hero-text-overlay-container-defaults mixin
   * contains the default CSS for a left side text overlay.
   */
  textOverlay: PropTypes.object,
  /** A custom class name */
  classNames: PropTypes.string,
  programLogo: PropTypes.string,
  heroGraphic: PropTypes.string,
};

export default Hero;
