import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Hero from '../components/Hero';
import Subfooter from '../components/Subfooter';
import Image from '../components/Image';
import Icon from '../components/Icon';
import Button from '../components/Button';
import OutboundLink from '../components/OutboundLink';
import '../styles/styles.scss';
import '../styles/pages/_home.scss';

const IndexPage = () => (
  <Layout title="Home">
    <Hero
      mediaSource={'home-image-hero.jpg'}
      programLogo={'Taked-BetterHealthinAction-tagline-Black-and-Red.svg'}
      heroGraphic={'home-image-molecule@2x.png'}
      className={'hero--large'}
      color={'blue'}
    ></Hero>
    {/* Banner definiton */}
    <Section>
      <Container>
        <Row className="home__lang-row">
          <Column size={10} className="home__background">
            <p className="home__quote-text">
              “Health equity is defined as the absence of unfair and avoidable
              or remediable differences in health among population groups
              defined socially, economically, demographically or
              geographically.” <br />
              <span className="home__quote-source">
                - World Health Organization
              </span>
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row className="home__lang-row">
          <Column size={10}>
            <Image filename="global-languages-promo-box@2x.png" />
          </Column>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <h2 className="h2">
          Empower the next generation of health equity champions
        </h2>
        <p>
          Discover ways to promote health literacy in your community and empower
          students with the knowledge and tools to help create a more equitable
          future for our world.
        </p>
        <Row className="mb-2">
          <Column size={6}>
            <OutboundLink to="/health-equity">
              <Image filename="home-image-VTS@2x.jpg" />
            </OutboundLink>
            <h3 className="h3">Video Topic Series</h3>
            <p className="text-bold">
              Explore the keys to health equity from local to global..
            </p>
            <p>
              This three-part video series immerses students in the lives of
              global health professionals working to eliminate health
              disparities by harnessing innovative STEM solutions.
            </p>
            <Button to="/video-topic-series" className="button--link">
              Learn more <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
          <Column size={6}>
            <OutboundLink to="/self-paced-module">
              <Image filename="home-image-SPM@2x.jpg" />
            </OutboundLink>
            <h3 className="h3">Self-Paced Module</h3>
            <p className="text-bold">
              Investigate the root causes of health inequities
            </p>
            <p>
              Give students the tools to take action in their own communities by
              learning about the social determinants of health, and how they
              contribute to health equity.
            </p>
            <Button to="/self-paced-module" className="button--link">
              Learn more <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
        </Row>
        <Row>
          <Column size={6}>
            <OutboundLink to="/health-equity">
              <Image filename="home-image-ChampHE.jpg" />
            </OutboundLink>
            <h3 className="h3">Championing Health Equity</h3>
            <p className="text-bold">Launch important conversations locally.</p>
            <p>
              <span className="text-italic">Better Health in Action </span>
              provides students, educators, and family members with interactive
              resources to elevate health equity conversations with the goal of
              sparking real change.
            </p>
            <Button to="/health-equity" className="button--link">
              Learn more <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
          <Column size={6}>
            <OutboundLink to="/career-profiles">
              <Image filename="home-image-CP.jpg" />
            </OutboundLink>
            <h3 className="h3">Career Profiles</h3>
            <p className="text-bold">
              Ignite student interest in careers incorporating health equity.
            </p>
            <p>
              Introduce students to STEM pioneers supporting health equity and
              let them step into the worlds of the diverse professionals working
              to build a better future.
            </p>
            <Button to="/career-profiles" className="button--link">
              Learn more <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>
    {/** Central STAT*/}
    <Section className="home-stat">
      <Container className="home__stat-container">
        <Row>
          {/* <Image
            filename="home-image-STATpattern@2x.png"
            className="home__stat-image"
          /> */}
          <div className="home__stat-background">
            <div className="home__stat-text">
              According to the World Health Organization, health equity action
              requires not only equitable access to healthcare, but also means
              working outside the healthcare system to address broader social
              well-being and development.
            </div>
          </div>
        </Row>
      </Container>
    </Section>

    {/** ABOUT */}
    <Section>
      <Container>
        <Row>
          <Column size={7}>
            <Image filename="home-image-AboutPrgrm.jpg" />
          </Column>
          <Column size={5}>
            <h3 className="h3">About the Program</h3>
            <p>
              Building a healthier future begins with informed agents of change
              taking action here and now. Join
              <span className="text-italic"> Takeda</span> and Discovery
              Education as we aim to inspire students in grades 6–8 to embrace
              health literacy and confront the realities of global health
              inequities with creative solutions.
              <span className="text-italic"> Better Health in Action </span>
              provides standards-aligned educational resources that allow
              students to view their lives through a health equity lens and find
              ways to impact their communities and the world.
            </p>
            <Button to="/about" className="button--link">
              Learn more <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>

    <Subfooter
      className="subfooter__quote-home"
      footerImage="home-image-QuoteBox@2x.png"
      footerText="We know that a child’s life expectancy is predicted more by his ZIP code than their genetic code."
      footerSourceOne={'Risa Lavizzo-Mourey, MD MBA,'}
      footerSourceTwo={'Former President and CEO,'}
      footerSourceThree={'Robert Wood Johnson Foundation'}
    />
  </Layout>
);

export default IndexPage;
